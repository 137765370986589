import React from 'react';
import RichText from 'components/rich-text';
import PropTypes from 'prop-types';

const FooterColumn = ({ heading, textHtml }) => {
  return (
    <div className="footer-column">
      {heading && <strong className="footer-column__heading">{heading}</strong>}
      {textHtml && <RichText text={textHtml} className="rich-text_in-footer" />}
    </div>
  );
};

FooterColumn.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
};

export default FooterColumn;
