import React from 'react';
import PropTypes from 'prop-types';

const BackgroundVideo = ({ url }) => {
  return (
    <div className="background-video__container">
      <video
        src={url}
        className="background-video"
        loop
        autoPlay
        muted
        playsInline
      />
    </div>
  );
};

BackgroundVideo.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BackgroundVideo;
