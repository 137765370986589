import React from 'react';
import PropTypes from 'prop-types';
import Language from './language';
import Link from 'components/link';
import Icon from 'components/icon';
import useToggle from 'hooks/use-toggle';
import useDependentToggle from 'hooks/use-dependent-toggle';
import messageBus from 'js/message-bus';
import cn from 'classnames';

const LanguageSelector = ({ className, lang, languages }) => {
  const {
    isActive: isMobileSelectorOpened,
    toggle,
    deactivate,
  } = useToggle(false);

  useDependentToggle({
    isActive: isMobileSelectorOpened,
    messageTypeToSend: messageBus.messageTypes.mobileLanguageSelectorOpened,
    messageTypeToSubscribe: messageBus.messageTypes.mobileMenuOpened,
    deactivate,
  });

  return (
    <div
      className={cn('language-selector', {
        [className]: className,
      })}
    >
      <button className="language-selector__opener" onClick={toggle}>
        <Icon
          name={isMobileSelectorOpened ? 'close' : 'language'}
          className="language-selector__opener-icon"
        />
        <span className="language-selector__opener-text">
          {lang.languagesLabel}
        </span>
      </button>
      {languages && languages.length > 0 && (
        <ul
          className={cn('language-selector__list', {
            'language-selector__list_active-mobile': isMobileSelectorOpened,
          })}
        >
          {languages.map((item, index) => (
            <li
              key={index}
              className={cn('language-selector__item', {
                'language-selector__item_current': item.isCurrent,
              })}
            >
              {item.url ? (
                <Link
                  url={item.url}
                  text={item.name}
                  className="language-selector__link"
                ></Link>
              ) : (
                item.name
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

LanguageSelector.viewModelMeta = {
  className: 'ignore',
};

LanguageSelector.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.shape({
    languagesLabel: PropTypes.string.isRequired,
  }).isRequired,
  languages: PropTypes.arrayOf(PropTypes.exact(Language.propTypes)),
};

export default LanguageSelector;
