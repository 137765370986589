import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';
import BreadcrumbsSchema from './breadcrumbsSchema';

const Breadcrumbs = ({
  links,
  className,
  lang = { ariaLabel: 'Breadcrumb' },
}) => {
  return (
    <>
      <BreadcrumbsSchema links={links} />
      <nav className={cn('breadcrumbs', className)} aria-label={lang.ariaLabel}>
        {links && (
          <ol className="breadcrumbs__items">
            {links.map((link, i) => {
              const ariaCurrent = i === links.length - 1 ? 'page' : null;

              return (
                <li key={i} className="breadcrumbs__item">
                  {link.url ? (
                    <Link
                      {...link}
                      className="breadcrumbs__link"
                      ariaCurrent={ariaCurrent}
                    />
                  ) : (
                    <span aria-current={ariaCurrent}>{link.text}</span>
                  )}
                </li>
              );
            })}
          </ol>
        )}
      </nav>
    </>
  );
};

Breadcrumbs.propTypes = {
  lang: PropTypes.shape({
    ariaLabel: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  className: PropTypes.string,
};

Breadcrumbs.viewModelMeta = {
  className: 'ignore',
};

export default Breadcrumbs;
